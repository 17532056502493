<template>
  <div class="contact">
    <wrapper-with-loader :isLoading="isLoading">
      <breadcrumb-list :breadcrumbList="breadcrumbList" />
      <page-title :pageTitle="pageTitle" />
      <content-wrapper>
        <div class="flex flex-col lg:flex-row">
          <div class="flex flex-col lg:w-72 mb-4 lg:mb-0">
            <div class="mb-4 lg:mb-12">
              <div class="text-gray-400">
                {{ $t('user.contact.titleOfTheSeller') }}
              </div>
              <div class="text-sm">info@serenas.com.tr</div>
            </div>
            <div>
              <div class="text-gray-400">
                {{ $t('user.contact.customerServices') }}
              </div>
              <div class="text-sm">+90 (312) 440 50 11</div>
            </div>
          </div>
          <div class="flex flex-col w-auto">
            <div class="mb-4 lg:mb-12">
              <div class="text-gray-400">{{ $t('user.contact.address') }}</div>
              <div class="text-sm">
                Hilal Mah. Cezayir Cad. No:13 Yıldız-Çankaya\Ankara
              </div>
            </div>
            <div class="flex flex-col lg:flex-row">
              <div class="lg:w-72 mb-4 lg:mb-0">
                <div class="text-gray-400">
                  {{ $t('user.contact.faxNumber') }}
                </div>
                <div class="text-sm">+90 (312) 441 45 64</div>
              </div>
              <div>
                <div class="text-gray-400">
                  {{ $t('user.contact.phoneNumber') }}
                </div>
                <div class="text-sm">+90 (312) 440 50 11</div>
              </div>
            </div>
          </div>
        </div>
      </content-wrapper>
      <div class="contact-bg bottom-0 left-0 absolute hidden lg:block">
        <img src="@/assets/images/bottom-bg.png" />
      </div>
    </wrapper-with-loader>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useMeta } from 'vue-meta'


import {
  BreadcrumbList,
  PageTitle,
  WrapperWithLoader,
  ContentWrapper,
} from '@/components'

export default {
  name: 'ContactPage',
  components: {
    BreadcrumbList,
    PageTitle,
    WrapperWithLoader,
    ContentWrapper,
  },
  created() {
    useMeta(
      computed(() => ({
        title: this.$t('metaTitle.contact'),
      })),
    )
  },
  data() {
    return {
      breadcrumbList: [{ name: this.$t('sidebar.contact') }],
      pageTitle: this.$t('sidebar.contact'),
      isLoading: true,
    }
  },
  mounted() {
    this.isLoading = false
  },
}
</script>

<style scoped lang="scss">
.contact {
  @apply h-full;
}
</style>
