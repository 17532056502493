<template>
  <div class="payment">
    <wrapper-with-loader :isLoading="isLoading">
      <step-wizard :step="Number(2)" :stepList="stepList" />
      <template v-if="getBasket && getBasket.items.length">
        <div class="flex flex-col xl:flex-row">
          <div class="w-full xl:w-2/3 xl:pr-5">
            <div class="mb-4" v-if="getAccountCurrent">
              <billing-info
                :propFormData="formData"
                :isCorporateInvoice="isCorporateInvoice"
                :toggleCorporateInvoice="toggleCorporateInvoice"
              />
            </div>
            <div class="mb-4">
              <payment-tab
                @changePaymentType="changePaymentType"
                :setFormData="setFormData"
              />
            </div>
          </div>

          <basket-summary
            :basket="getBasket"
            :propFormData="formData"
            :paymentType="paymentType"
            :isCorporateInvoice="isCorporateInvoice"
          />
        </div>
      </template>
    </wrapper-with-loader>
  </div>
</template>

<script>
import { computed } from 'vue'
import { mapGetters } from 'vuex'
import { useResponseToast, useToast, useMeta } from '@/common/hooks'
import { service } from '@/common/services'
import router from '@/router'
import { WrapperWithLoader, StepWizard } from '@/components'
import BasketSummary from './BasketSummary.vue'

import BillingInfo from './BillingInfo.vue'
import PaymentTab from './PaymentTab.vue'

export default {
  name: 'PaymentPage',
  components: {
    WrapperWithLoader,
    BasketSummary,
    StepWizard,
    BillingInfo,
    PaymentTab,
  },
  data() {
    return {
      isLoading: true,
      stepList: [
        {
          key: 1,
          value: this.$t('shared.basket'),
        },
        {
          key: 2,
          value: this.$t('shared.payment'),
        },
        {
          key: 3,
          value: this.$t('shared.finish'),
        },
      ],
      formData: {
        invoiceName: null,
        invoiceCountryId: null,
        invoiceCityId: null,
        invoiceStateId: null,
        invoiceTaxOffice: null,
        invoiceTaxNumber: null,
        ccOwner: null,
        cardNumber: null,
        expiryMonth: null,
        expiryYear: null,
        cvv: null,
        installmentCount: 0,
      },
      paymentType: 'credit-card-tab',
      isCorporateInvoice: false,
    }
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  created() {
    useMeta(
      computed(() => ({
        title: this.$t('metaTitle.payment'),
      })),
    )
  },

  mounted() {
    this.fetchOrderValidation()
    if (this.getAccountCurrent) {
      this.getBillingInfo(this.getAccountCurrent.item)
    }
  },
  watch: {
    getAccountCurrent: function (data) {
      return this.getBillingInfo(data.item)
    },
  },
  methods: {
    fetchOrderValidation() {
      service
        .fetch('order/validation')
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {},
            errorCallback: () => {
              response.messages.map((message) => {
                if (
                  message.code ===
                  'FEATURE_VALIDATION_ORDER_VALIDATION_INSIDE_PRODUCT_TYPE_CONTROL'
                ) {
                  router.push({
                    name: 'congress/id',
                    params: { id: message.message },
                  })
                } else {
                  router.push({ name: 'basket' })
                }
              })
            },
            isSaveMessage: false,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getBillingInfo(data) {
      this.formData = {
        ...this.formData,
        invoiceName: data.invoiceName,
        invoiceCountryId: data.invoiceCountryId,
        invoiceCityId: data.invoiceCityId,
        invoiceStateId: data.invoiceStateId,
        invoiceTaxOffice: data.invoiceTaxOffice,
        invoiceTaxNumber: data.invoiceTaxNumber,
        invoiceAddress: data.invoiceAddress,
      }
    },
    changePaymentType($event) {
      if ($event === 'transfer-tab') {
        this.formData = {
          ...this.formData,
          ccOwner: null,
          cardNumber: null,
          expiryMonth: null,
          expiryYear: null,
          cvv: null,
        }
      }
      this.paymentType = $event
    },
    setFormData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
    },
    toggleCorporateInvoice() {
      this.isCorporateInvoice = !this.isCorporateInvoice
    },
  },
  computed: {
    ...mapGetters(['getBasket', 'getAccountCurrent']),
  },
}
</script>

<style scoped lang="scss">
.payment {
  @apply h-full;
}
</style>
