<template>
  <content-wrapper>
    <div class="tab">
      <tabs variant="underline" v-model="activeTab">
        <tab
          v-for="(item, key) in paymentItems"
          :name="item.component"
          :key="key"
          :title="$t(`user.payment.${item.name}`)"
        >
          <component
            :is="`${item.component.toLowerCase()}`"
            :item="item"
            :key="item"
            :setFormData="setFormData"
            v-bind="$attrs"
          />
        </tab>
      </tabs>
    </div>
  </content-wrapper>
</template>

<script>
import { Tabs, Tab } from "flowbite-vue";
import { ref } from "vue";
import { ContentWrapper } from "@/components";
import CreditCardTab from "./CreditCard.vue";
import TransferTab from "./Transfer.vue";

export default {
  name: "PaymentTab",
  components: { Tabs, Tab, CreditCardTab, TransferTab, ContentWrapper },
  data() {
    return {
      activeTab: ref("credit-card-tab"),
      paymentItems: [
        {
          name: "creditCard",
          component: "credit-card-tab",
        },
        {
          name: "transfer",
          component: "transfer-tab",
        },
      ],
    };
  },
  props: {
    setFormData: Function,
  },
  watch: {
    activeTab: function (data) {
      this.$emit("changePaymentType", data);
    },
  },
};
</script>
