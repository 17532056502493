import { authService } from "@/common/services";
import { createRouter, createWebHistory } from "vue-router";

import {
  Basket,
  Congress,
  CongressContents,
  CongressDetail,
  CongressEdit,
  Contact,
  Home,
  Login,
  Order,
  OrderCompleted,
  OrderDetail,
  Payment,
  PrivacyPolicy,
  Profile,
  Register,
  SerenasLogin,
  TermsOfUse,
  UserCongress,
  UserOrder,
  UserOrderDetail,
  DetailedOrder,
} from "@/pages";

const routes = [
  {
    path: "/",
    component: Home,
    name: "home",
    meta: {
      layout: "AuthorizeLayout",
      authorize: true,
    },
  },
  {
    path: "/basket",
    component: Basket,
    name: "basket",
    meta: {
      layout: "AuthorizeLayout",
      authorize: true,
    },
  },
  {
    path: "/payment",
    component: Payment,
    name: "payment",
    meta: {
      layout: "AuthorizeLayout",
      authorize: true,
    },
  },
  {
    path: "/order-completed/:id",
    component: OrderCompleted,
    name: "order-completed/id",
    meta: {
      layout: "AuthorizeLayout",
      authorize: true,
    },
  },
  {
    path: "/order",
    component: UserOrder,
    name: "userOrder",
    meta: {
      layout: "AuthorizeLayout",
      authorize: true,
    },
  },
  {
    path: "/order/:id",
    component: UserOrderDetail,
    name: "userOrder/id",
    meta: {
      layout: "AuthorizeLayout",
      authorize: true,
    },
  },
  {
    path: "/congress/:id",
    component: UserCongress,
    name: "congress/id",
    meta: {
      layout: "AuthorizeLayout",
      authorize: true,
    },
  },
  {
    path: "/profile",
    component: Profile,
    name: "profile",
    meta: {
      layout: "AuthorizeLayout",
      authorize: true,
    },
  },
  {
    path: "/contact",
    component: Contact,
    name: "contact",
    meta: {
      layout: "AuthorizeLayout",
      authorize: true,
    },
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    name: "privacyPolicy",
    meta: {
      layout: "AuthorizeLayout",
      authorize: true,
    },
  },
  {
    path: "/terms-of-use",
    component: TermsOfUse,
    name: "termsOfUse",
    meta: {
      layout: "AuthorizeLayout",
      authorize: true,
    },
  },

  {
    path: "/admin",
    name: "admin",
    meta: {
      layout: "AuthorizeLayout",
      authorize: true,
      isAdmin: true,
    },
    children: [
      {
        path: "congress",
        component: Congress,
        name: "congress",
      },
      {
        path: "congress/:id",
        component: CongressDetail,
        name: "congress/detail",
      },
      {
        path: "congress/edit/:id",
        component: CongressEdit,
        name: "congress/edit/id",
      },
      {
        path: "congress/edit",
        component: CongressEdit,
        name: "congress/edit",
      },
      {
        path: "congress/contents/:id",
        component: CongressContents,
        name: "congress/contents/id",
      },
      {
        path: "order",
        component: Order,
        name: "order",
      },
      {
        path: "order/:id",
        component: OrderDetail,
        name: "order/id",
        meta: {
          layout: "AuthorizeLayout",
          authorize: true,
        },
      },
      {
        path: "detailedOrder",
        component: DetailedOrder,
        name: "detailedOrder",
        meta: {
          layout: "AuthorizeLayout",
          authorize: true,
        },
      },
    ],
  },

  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      layout: "PublicLayout",
    },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      layout: "PublicLayout",
    },
  },
  {
    path: "/signin-oidc",
    name: "signin-oidc",
    component: SerenasLogin,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const { authorize, isAdmin } = to.meta;
  const currentUser = authService.accountCurrentValue();
  if (authorize) {
    if (!currentUser || !currentUser.token) {
      const returnUrl = window.location.pathname;
      localStorage.setItem("returnUrl", returnUrl);

      return next({ path: "/login", query: { returnUrl } });
    }
    if (isAdmin && !currentUser.isAdmin) {
      return next({
        path: "/",
      });
    }
  }
  const paths = ["login", "register"];
  const authPass = new RegExp(paths.join("|")).test(to.path);
  if (currentUser?.token && authPass) {
    return next({
      path: "/",
    });
  }
  next();
});

export default router;
