import { createStore } from "vuex";
import { service } from "@/common/services";

const store = createStore({
  modules: {},
  state: {
    isSidebar: window.innerWidth > 1024 ? true : false,
    isMobile: window.innerWidth < 1024,
    user: null,
    basket: {
      salePrice: 0,
      totalPrice: 0,
      totalVat: 0,
      items: [],
      wireTransferInformations: [],
    },
    lang: localStorage.getItem("lang") ? localStorage.getItem("lang") : "TR",
    isToggleLang: false,
  },
  getters: {
    getSidebar(state) {
      return state.isSidebar;
    },
    isMobile(state) {
      return state.isMobile;
    },
    getAccountCurrent(state) {
      return state.user;
    },
    getBasket(state) {
      return state.basket;
    },
    getLang(state) {
      return state.lang;
    },
    getToggleLang(state) {
      return state.isToggleLang;
    },
  },
  actions: {
    fetchAccountCurrent({ commit }) {
      return service.fetch("account/current").then(
        (user) => {
          console.log(user);
          commit("successUser", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("errorUser");
          return Promise.reject(error);
        }
      );
    },
    fetchBasket({ commit }) {
      return service.fetch("basket").then(
        (basket) => {
          commit("successBasket", basket);
          return Promise.resolve(basket);
        },
        (error) => {
          commit("clearBasket");
          return Promise.reject(error);
        }
      );
    },
    setSidebar({ commit }) {
      commit("successSidebar");
    },
    setLang({ commit }, lang) {
      if (lang === "EN") {
        document.querySelector("html").setAttribute("lang", "en");
      } else {
        document.querySelector("html").setAttribute("lang", "tr");
      }
      commit("successLang", lang);
    },
    setToggleLang({ commit }, isToggleLang) {
      commit("successToggleLang", isToggleLang);
    },
  },
  mutations: {
    successSidebar(state) {
      state.isSidebar = !state.isSidebar;
    },
    successUser(state, user) {
      state.user = user;
    },
    errorUser(state) {
      state.user = null;
    },
    successBasket(state, basket) {
      state.basket = basket;
      if (basket.wireTransferInformations != null)
        basket.wireTransferInformations = JSON.parse(
          basket.wireTransferInformations
        );
    },
    clearBasket(state) {
      state.basket = {
        salePrice: 0,
        totalPrice: 0,
        totalVat: 0,
        items: [],
      };
    },
    successLang(state, lang) {
      state.lang = lang;
    },
    successToggleLang(state, isToggleLang) {
      state.isToggleLang = isToggleLang;
    },
  },
});

export default store;
