<template>
  <div
    class="flex flex-col lg:flex-row justify-between p-4 bg-white dark:bg-gray-900"
  >
    <div class="flex flex-col lg:flex-row items-center gap-4 mb-4 lg:mb-0">
      <div class="w-full flex-auto">
        <div class="relative">
          <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <MagnifyingGlassIcon
              class="w-5 h-5 text-gray-500 dark:text-gray-400"
            />
          </div>
          <input
            type="text"
            id="table-search"
            v-model="formData.searchText"
            @keyup.enter="search($event.target.value)"
            class="block p-2 pl-10 w-full lg:w-48 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            :placeholder="$t('shared.search')"
          />
        </div>
      </div>
      <div class="w-full flex-auto">
        <select
          id="productType"
          v-model="formData.productType"
          @change="filterProductType($event)"
          class="w-full lg:w-[200px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="null">
            {{ $t('shared.all') }}
          </option>
          <option value="1">
            {{ $t('admin.congressDetail.inside') }}
          </option>
          <option value="2">
            {{ $t('admin.congressDetail.outside') }}
          </option>
          <option value="3">
            {{ $t('admin.congressDetail.accommodation') }}
          </option>
          <option value="4">
            {{ $t('admin.congressDetail.transfer') }}
          </option>
          <option value="5">
            {{ $t('admin.congressDetail.course') }}
          </option>
        </select>
      </div>

      <div v-if="isClearFilter" class="whitespace-nowrap flex-auto">
        <a
          @click="filterReset"
          class="cursor-pointer underline text-sm text-blue-500"
        >
          {{ $t('user.order.filterReset') }}
        </a>
      </div>
    </div>

    <div class="flex flex-col lg:flex-row rounded-lg border py-2 px-3 bg-gray-200">
      <div class="block pr-0 mb-2 lg:pr-4 lg:mb-0">
        <select
          id="productType"
          @change="changeProductType($event.target.value)"
          class="w-full lg:w-[200px] bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option :selected="productType === null" value="null" disabled>
            {{ $t('admin.congressDetail.chooseProductType') }}
          </option>
          <option :selected="productType === 1" value="1">
            {{ $t('admin.congressDetail.inside') }}
          </option>
          <option :selected="productType === 2" value="2">
            {{ $t('admin.congressDetail.outside') }}
          </option>
          <option :selected="productType === 3" value="3">
            {{ $t('admin.congressDetail.accommodation') }}
          </option>
          <option :selected="productType === 4" value="4">
            {{ $t('admin.congressDetail.transfer') }}
          </option>
          <option :selected="productType === 5" value="5">
            {{ $t('admin.congressDetail.course') }}
          </option>
        </select>
      </div>
      <div class="block">
        <button
          type="button"
          @click="openModal"
          class="inline-flex gap-1 justify-center items-center w-full lg:w-auto text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xs px-5 py-2.5 mr-2 mb-2 lg:mb-0 lg:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
          <span>{{ $t('admin.congress.add') }}</span>
        </button>
      </div>
    </div>

    <div
      id="add-congress-detail-modal"
      tabindex="-1"
      aria-hidden="true"
      class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full lg:inset-0 h-modal lg:h-full"
    >
      <div class="relative p-4 w-full max-w-2xl h-full lg:h-auto">
        <!-- Modal content -->
        <div
          class="modal-scroll relative bg-white rounded-lg shadow h-full lg:h-[650px] overflow-auto dark:bg-gray-700"
        >
          <!-- Modal header -->
          <div
            class="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600"
          >
            <h3
              class="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white"
            >
              {{ $t('admin.congressDetail.addCongressDetail') }}
            </h3>
            <button
              type="button"
              @click="closeModal"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <XMarkIcon class="w-5 h-5" />
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-6 space-y-6">
            <congress-detail-edit-form
              :productType="productType"
              :dispatchCongressDetailListByFilter="dispatchCongressDetailListByFilter"
              :closeModal="closeModal"
              :payload="payload"
              :currency="currency"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  XMarkIcon,
  MagnifyingGlassIcon,
} from '@heroicons/vue/20/solid'
import { useToast } from '@/common/hooks'
import CongressDetailEditForm from './CongressDetailEditForm.vue'

export default {
  name: 'CongressDetailAction',
  components: {
    CongressDetailEditForm,
    XMarkIcon,
    MagnifyingGlassIcon,
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      isSelectedProductType: false,
      isClearFilter: false,
      modal: null,
      productType: null,
      formData: {
        searchText: '',
      },
    }
  },
  props: {
    dispatchCongressDetailListByFilter: Function,
    search: Function,
    filterProductType: Function,
    filterReset:Function,
    payload: {},
    congressDetail: Object,
    currency:Number,
  },
  watch: {
    payload: function (data) {
      this.formData = data
      this.isCheckClearFilter(data)
      return data
    },
  },

  mounted() {
    this.initModal()
    this.formData = this.payload
    this.isCheckClearFilter(this.payload)
  },
  methods: {
    openModal() {
      if (!this.isSelectedProductType) {
        this.toast.error(this.$t('admin.congressDetail.chooseProductType'))
      } else {
        this.changeProductType(this.productType)
        this.modal.show()
      }
    },
    closeModal() {
      this.modal.hide()
    },
    changeProductType(value) {
      this.isSelectedProductType = value !== null
      this.productType = value
    },
    initModal() {
      const modalEl = document.getElementById('add-congress-detail-modal')
      const modalOptions = {
        placement: 'center-center',
        backdropClasses:
          'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
        onShow: () => {
          document.querySelector(
            '#add-congress-detail-modal .modal-scroll',
          ).scrollTop = 0
        },
      }
      if (modalEl) {
        // eslint-disable-next-line
        this.modal = new Modal(modalEl, modalOptions)
      }
    },
    isCheckClearFilter(data) {
      const { searchText, productType } = data
      if (searchText !== '' || productType !== null) {
        this.isClearFilter = true
      } else {
        this.isClearFilter = false
      }
    },
  },
}
</script>
