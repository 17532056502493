<template>
  <div class="home">
    <wrapper-with-loader :isLoading="isLoading">
      <ul class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 max-w-screen-xl">
        <li v-for="congress in activeItems" :key="congress.id"
          class="bg-white border border-gray-200 rounded-lg shadow relative">
          <div class="absolute right-0 top-0 flex justify-center items-center z-50 w-12 h-12">
            <Popper :hover="true" closeDelay="500" :arrow="true">
              <button @click="copyUrl(congress.id)">
                <LinkIcon class="w-5 h-5 text-[#1da1f2]" />
              </button>
              <template #content>
                <span v-if="isCopied" class="text-xs whitespace-nowrap">
                  {{ $t('shared.copiedUrl') }}
                </span>
                <span v-else class="text-xs whitespace-nowrap">
                  {{ $t('shared.copyUrl') }}
                </span>
              </template>
            </Popper>
          </div>
          <router-link :to="{ name: 'congress/id', params: { id: congress.id } }"
            class="flex flex-col flex-grow h-full items-center w-full bg-white rounded-lg hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
            <img v-if="congress.imagePath" class="object-cover w-full aspect-[2/1] rounded-t-lg" :src="congress.imagePath"
              :alt="[getLang === 'TR' ? congress.name : congress.nameEn]" />
            <img v-else src="@/assets/images/default-congress-image.jpeg"
              :alt="[getLang === 'TR' ? congress.name : congress.nameEn]"
              class="object-cover w-full aspect-[2/1] rounded-t-lg" />
            <div class="p-4 leading-normal w-full">
              <h5 class="flex gap-1 mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                <span v-if="getLang === 'TR'">
                  {{ congress.name }}
                </span>
                <span v-if="getLang === 'EN'">
                  {{ congress.nameEn }}
                </span>
              </h5>
              <div class="flex flex-col md:flex-row justify-between gap-4 border-b border-gray-200 pb-1 mb-1">
                <div>
                  <span class="text-xs text-gray-400">
                    {{ $t('home.startDate') }}:
                  </span>
                  <span class="text-xs text-gray-900">
                    {{ moment(congress.startDate).format('DD.MM.YYYY') }}
                  </span>
                </div>
                <div>
                  <span class="text-xs text-gray-400">
                    {{ $t('home.endDate') }}:
                  </span>
                  <span class="text-xs text-gray-900">
                    {{ moment(congress.endDate).format('DD.MM.YYYY') }}
                  </span>
                </div>
              </div>
              <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                <span v-if="getLang === 'TR'" v-html="congress.description"></span>
                <span v-if="getLang === 'EN'" v-html="congress.descriptionEn"></span>
              </p>
            </div>
          </router-link>
        </li>
      </ul>
    </wrapper-with-loader>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useMeta } from 'vue-meta'
import { mapGetters } from 'vuex'
import { useResponseToast, useToast } from '@/common/hooks'
import { service } from '@/common/services'
import Popper from 'vue3-popper'
import moment from 'moment'
import { WrapperWithLoader } from '@/components'
import { LinkIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'HomePage',
  components: {
    WrapperWithLoader,
    LinkIcon,
    Popper,
  },
  data() {
    return {
      moment: moment,
      activeItems: Array,
      isLoading: true,
      isCopied: false,
    }
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  created() {
    useMeta(
      computed(() => ({
        title: this.$t('metaTitle.home'),
      })),
    )
    this.fetchCongressActiveList()
  },
  watch: {
    activeItems: function (data) {
      return (this.activeItems = data)
    },
  },
  methods: {
    fetchCongressActiveList() {
      service
        .fetch('congress/active-list')
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              if (response.items.length) {
                this.activeItems = response.items
              } else {
                this.activeItems = []
              }
            },
            errorCallback: () => { },
            isSaveMessage: false,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    copyUrl(id) {
      this.isCopied = true
      navigator.clipboard.writeText(`${window.location.href}congress/${id}`)
      setTimeout(() => {
        this.isCopied = false
      }, 1000)
    },
  },
  computed: {
    ...mapGetters(['getLang']),
  },
}
</script>

<style scoped lang="scss">
.home {
  @apply h-full;
}
</style>
