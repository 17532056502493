<template>
  <div>
    <div class="flex gap-4 flex-wrap my-7" v-if="downXl">
      <content-wrapper v-for="(type, key) in participantTypes" :key="key">
        <div class="flex flex-col">
          <div class="text-2xl font-semibold truncate text-gray-500 mb-6">
            <span v-if="getLang === 'TR'">
              {{ type.participantType }}
            </span>
            <span v-if="getLang === 'EN'">
              {{ type.participantTypeEn }}
            </span>
          </div>
          <div class="flex flex-col mb-4">
            <label
              v-for="item in type.items"
              :key="item"
              :for="`participant-${item.id}`"
              class="relative border-2 border-blue-600 rounded-xl p-4 lg:p-6 flex flex-col lg:flex-row items-center justify-between mb-4 last:mb-0"
            >
              <div
                class="absolute lg:relative left-3 lg:left-0 top-0 bottom-0 flex items-center"
              >
                <div class="flex items-center">
                  <input
                    :id="`participant-${item.id}`"
                    type="radio"
                    :value="item.id"
                    name="list"
                    @change="changePackage($event, item.id)"
                    class="radio-package w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
              </div>
              <div
                class="flex flex-col flex-1 ml-4 font-normal truncate text-gray-500"
              >
                <span class="text-base lg:text-lg" v-if="getLang === 'TR'">
                  {{ item.name }}
                </span>
                <span class="text-base lg:text-lg" v-if="getLang === 'EN'">
                  {{ item.nameEn }}
                </span>
                <div
                  class="text-base"
                  v-if="getLang === 'TR'"
                  v-html="item.description"
                ></div>
                <div
                  class="text-base"
                  v-if="getLang === 'EN'"
                  v-html="item.descriptionEn"
                ></div>
                <div class="text-xl font-extrabold text-gray-900">
                  {{ toCurrency(item.price, "tr-TR", "currency", getCongressProductCurrency(congress.productCurrency)) }}
                </div>
                <div class="text-xs font-semibold text-gray-900" v-if="item.vatRate != 0">
                  ({{ $t('user.congress.vatRate', { value: item.vatRate }) }})
                </div>
                <span class="text-xs lg:text-sm">
                  {{ $t('user.congress.lastRegistrationDate') }} :
                  {{ moment(item.lastRegistrationDate).format('DD.MM.YYYY') }}
                </span>
              </div>
              <div class="flex flex-col items-end">
                <Popper :hover="true" :arrow="true" placement="top">
                  <button>
                    <InformationCircleIcon class="w-6 h-6 text-[#1da1f2]" />
                  </button>
                  <template #content>
                    <p class="text-xs lg:text-sm">
                      {{ $t('user.congress.startDate') }} :
                      {{ moment(item.startDate).format('DD.MM.YYYY') }}
                    </p>
                    <p class="text-xs lg:text-sm">
                      {{ $t('user.congress.endDate') }} :
                      {{ moment(item.endDate).format('DD.MM.YYYY') }}
                    </p>
                  </template>
                </Popper>
              </div>
            </label>
          </div>
        </div>
        <div>
          <button-with-loader
            :disabled="true"
            @click="dispatchBasket(congressDetailId)"
            :isLoading="isLoading"
            type="button"
            class="plan-selector inline-flex gap-1 items-center justify-center w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:bg-gray-200"
          >
            <PlusIcon class="h-5 w-5 text-white font-bold" />
            {{ $t('shared.choosePlan') }}
          </button-with-loader>
        </div>
      </content-wrapper>
    </div>
    <div class="my-7 w-full" v-else>
      <carousel :items-to-show="1" :wrapAround="true">
        <slide v-for="(type, key) in participantTypes" :key="key">
          <div
            class="plan-group p-8 h-full flex items-center bg-white rounded-lg border border-gray-200 shadow-sm dark:bg-gray-800 dark:border-gray-700"
          >
            <div class="flex flex-col">
              <div class="text-2xl font-semibold text-gray-500 mb-6">
                <span v-if="getLang === 'TR'">
                  {{ type.participantType }}
                </span>
                <span v-if="getLang === 'EN'">
                  {{ type.participantTypeEn }}
                </span>
              </div>
              <div class="flex flex-col mb-4">
                <label
                  v-for="item in type.items"
                  :key="item"
                  :for="`participant-${item.id}`"
                  class="relative border-2 border-blue-600 rounded-xl p-4 lg:p-6 flex flex-col lg:flex-row items-center justify-between mb-4 last:mb-0"
                >
                  <div
                    class="absolute lg:relative left-3 lg:left-0 top-0 bottom-0 flex items-center"
                  >
                    <div class="flex items-center">
                      <input
                        :id="`participant-${item.id}`"
                        type="radio"
                        :value="item.id"
                        name="list"
                        @change="changePackage($event, item.id)"
                        class="radio-package w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </div>
                  </div>
                  <div
                    class="flex flex-col flex-1 ml-4 font-normal text-gray-500"
                  >
                    <span class="text-base lg:text-lg" v-if="getLang === 'TR'">
                      {{ item.name }}
                    </span>
                    <span class="text-base lg:text-lg" v-if="getLang === 'EN'">
                      {{ item.nameEn }}
                    </span>
                    <div
                      class="text-base"
                      v-if="getLang === 'TR'"
                      v-html="item.description"
                    ></div>
                    <div
                      class="text-base"
                      v-if="getLang === 'EN'"
                      v-html="item.descriptionEn"
                    ></div>
                    <div class="text-xl font-extrabold text-gray-900">
                      {{ toCurrency(item.price, "tr-TR", "currency", getCongressProductCurrency(congress.productCurrency)) }}
                    </div>
                    <div class="text-xs font-semibold text-gray-900" v-if="item.vatRate != 0">
                      ({{
                        $t('user.congress.vatRate', { value: item.vatRate })
                      }})
                    </div>
                    <span class="text-xs lg:text-sm">
                      {{ $t('user.congress.lastRegistrationDate') }} :
                      {{
                        moment(item.lastRegistrationDate).format('DD.MM.YYYY')
                      }}
                    </span>
                  </div>
                  <div class="flex flex-col items-end">
                    <Popper :hover="true" :arrow="true" placement="top">
                      <button>
                        <InformationCircleIcon class="w-6 h-6 text-[#1da1f2]" />
                      </button>
                      <template #content>
                        <p class="text-xs lg:text-sm">
                          {{ $t('user.congress.startDate') }} :
                          {{ moment(item.startDate).format('DD.MM.YYYY') }}
                        </p>
                        <p class="text-xs lg:text-sm">
                          {{ $t('user.congress.endDate') }} :
                          {{ moment(item.endDate).format('DD.MM.YYYY') }}
                        </p>
                      </template>
                    </Popper>
                  </div>
                </label>
              </div>
              <div>
                <button-with-loader
                  :disabled="true"
                  @click="dispatchBasket(congressDetailId)"
                  :isLoading="isLoading"
                  type="button"
                  class="plan-selector inline-flex gap-1 items-center justify-center w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:bg-gray-200"
                >
                  <PlusIcon class="h-5 w-5 text-white font-bold" />
                  {{ $t('shared.choosePlan') }}
                </button-with-loader>
              </div>
            </div>
          </div>
        </slide>

        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
    </div>
    <div class="flex flex-col p-4">
      <div v-if="getLang === 'TR'" v-html="congress.courseDesc"></div>
      <div v-if="getLang === 'EN'" v-html="congress.courseDescEn"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { ButtonWithLoader, ContentWrapper } from '@/components'
import { useMediaQuery } from '@/common/hooks'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { mapGetters } from 'vuex'
import { PlusIcon,InformationCircleIcon } from '@heroicons/vue/20/solid'
import Popper from 'vue3-popper'
import { toCurrency } from '@/common/utils'

export default {
  name: 'CourseTab',
  components: {
    ButtonWithLoader,
    ContentWrapper,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    PlusIcon,
    InformationCircleIcon,
    Popper
  },
  setup() {
    const { downXl } = useMediaQuery()
    return { downXl }
  },
  data() {
    return {
      moment: moment,
      isLoading: false,
      isChecked: false,
      congressDetailId: null,
    }
  },
  props: {
    participantTypes: Array,
    dispatchBasket: Function,
    congress: {},
  },
  methods: {
    getCongressProductCurrency(productCurrency){
      let _currency;
          switch (productCurrency) {
            case 1:
              _currency = "EUR";
              break;
            case 2:
              _currency = "USD";
              break;
            default:
              _currency = "TRY";
              break;
          }
         return _currency;
    },
    changePackage($event, value) {
      Array.from(document.querySelectorAll(".radio-package")).forEach((el) =>
          el.closest("label").classList.remove("bg-blue-100")
      );
      Array.from(document.querySelectorAll("button.plan-selector")).forEach((el) =>
        {
          el.disabled = true;
        }
      );
      $event.target.closest("label").classList.add("bg-blue-100");
      $event.target.closest('.plan-group').querySelector('button.plan-selector').disabled = false;
      this.congressDetailId = value
    },
    toCurrency
  },
  mounted() {},
  computed: {
    ...mapGetters(['getLang']),
  },
}
</script>
