<template>
  <div class="profile">
    <wrapper-with-loader :isLoading="isLoading">
      <breadcrumb-list :breadcrumbList="breadcrumbList" />
      <page-title :pageTitle="pageTitle" />
      <content-wrapper>
        <div class="text-xl font-bold mb-6">
          {{ $t('user.profile.personalInformation') }}
        </div>
        <profile-form :propFormData="getAccountCurrent.item" />
      </content-wrapper>
    </wrapper-with-loader>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useMeta } from 'vue-meta'
import {BreadcrumbList,PageTitle,WrapperWithLoader,ContentWrapper} from '@/components'
import ProfileForm from '@/pages/Profile/ProfileForm.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ProfilePage',
  components: {
    BreadcrumbList,
    PageTitle,
    ProfileForm,
    WrapperWithLoader,
    ContentWrapper,
  },
  created() {
    useMeta(
      computed(() => ({
        title: this.$t('metaTitle.profile'),
      })),
    )
  },
  data() {
    return {
      breadcrumbList: [{ name: this.$t('sidebar.profile') }],
      pageTitle: this.$t('sidebar.profile'),
      isLoading: true,
    }
  },
  watch: {
    getAccountCurrent: function (data) {
      this.isLoading = false
      return data
    },
  },
  mounted() {
    if (this.getAccountCurrent) {
      this.isLoading = false
    }
  },
  computed: {
    ...mapGetters(['getAccountCurrent']),
  },
}
</script>

<style scoped lang="scss">
.profile {
  @apply h-full;
}
</style>
