<template>
  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
    <thead
      class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
    >
      <tr>
        <th scope="col" class="p-4">
          {{ $t("user.basket.productType") }} -
          {{ $t("user.basket.name") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.basket.firstNameLastName") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.basket.price") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.basket.vat") }}
        </th>
        <th scope="col" class="p-4">
          {{ $t("user.basket.salePrice") }}
        </th>
        <th scope="col" class="py-3 w-12"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in basket.items"
        :key="item.id"
        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
      >
        <td class="p-4 font-medium text-gray-900 dark:text-white">
          <p
            class="flex-1 truncate w-[180px] 2xl:w-auto"
            :title="`${getLang === 'TR' ? item.name : item.nameEn}`"
          >
            <span v-if="item.productType === 1">{{ $t("shared.inside") }}</span>
            <span v-if="item.productType === 2">
              {{ $t("shared.outside") }}
            </span>
            <span v-if="item.productType === 3">
              {{ $t("shared.accommodation") }}
            </span>
            <span v-if="item.productType === 4">
              {{ $t("shared.transfer") }}
            </span>
            <span v-if="item.productType === 5">{{ $t("shared.course") }}</span>
            -
            <span v-if="getLang === 'TR'">
              {{ item.name }}
            </span>
            <span v-if="getLang === 'EN'">
              {{ item.nameEn }}
            </span>
          </p>
        </td>
        <th class="p-4">{{ item.firstName }} {{ item.surname }}</th>
        <th class="p-4">
          {{
            toCurrency(
              item.price,
              "tr-TR",
              "currency",
              getCongressProductCurrency(basket.productCurrency)
            )
          }}
        </th>
        <th class="p-4">
          <span v-if="item.vat != 0">{{
            toCurrency(
              item.vat,
              "tr-TR",
              "currency",
              getCongressProductCurrency(basket.productCurrency)
            )
          }}</span>
        </th>
        <th class="p-4">
          {{
            toCurrency(
              item.salePrice,
              "tr-TR",
              "currency",
              getCongressProductCurrency(basket.productCurrency)
            )
          }}
        </th>
        <td class="py-4 w-12">
          <div class="flex justify-center">
            <a
              @click="deleteBasketById(item.id)"
              class="cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline"
            >
              <XCircleIcon class="w-6 h-6" />
            </a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { useResponseToast, useToast } from "@/common/hooks";
import { service } from "@/common/services";
import { mapActions } from "vuex";
import { XCircleIcon } from "@heroicons/vue/20/solid";
import { toCurrency } from "@/common/utils";

export default {
  name: "BasketTableList",
  components: { XCircleIcon },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      moment: moment,
    };
  },
  props: {
    basket: Object,
  },
  methods: {
    getCongressProductCurrency(productCurrency) {
      let _currency;
      switch (productCurrency) {
        case 1:
          _currency = "EUR";
          break;
        case 2:
          _currency = "USD";
          break;
        default:
          _currency = "TRY";
          break;
      }
      return _currency;
    },
    ...mapActions(["fetchBasket"]),
    deleteBasketById(id) {
      service.delete(`basket/${id}`).then((response) => {
        useResponseToast({
          response: response,
          successCallback: () => {
            this.fetchBasket();
          },
          errorCallback: () => {},
          isSaveMessage: true,
        });
      });
    },
    toCurrency,
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>
