<template>
  <nav class="flex mb-8" aria-label="Breadcrumb">
    <ol class="inline-flex items-center space-x-1 md:space-x-3">
      <li v-if="breadcrumbBack" class="inline-flex items-center">
        <a
          href="#"
          @click="historyBack"
          class="disable block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          <ChevronLeftIcon class="w-5 h-5" />
        </a>
      </li>
      <li class="inline-flex items-center">
        <router-link
          :to="{ name: 'home' }"
          class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
        >
          <HomeIcon class="w-5 h-5 mr-2" />

          {{ $t('shared.home') }}
        </router-link>
      </li>
      <li aria-current="page" v-for="item in breadcrumbList" :key="item">
        <template v-if="item.url !== ''">
          <div class="flex items-center">
            <router-link
              :to="{ name: item.url }"
              class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
            >
              <ChevronRightIcon class="w-5 h-5" />

              <span
                class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400"
              >
                {{ item.name }}
              </span>
            </router-link>
          </div>
        </template>
        <template v-else>
          <div class="flex items-center">
            <ChevronRightIcon class="w-5 h-5" />
            <span
              class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400"
            >
              {{ item.name }}
            </span>
          </div>
        </template>
      </li>
    </ol>
  </nav>
</template>

<script>
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  HomeIcon,
} from '@heroicons/vue/20/solid'
export default {
  name: 'BreadcrumbList',
  components: { ChevronLeftIcon, ChevronRightIcon, HomeIcon },
  props: {
    breadcrumbList: Array,
    breadcrumbBack: Boolean,
  },
  methods: {
    historyBack() {
      window.history.back()
    },
  },
}
</script>
