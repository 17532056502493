<template>
  <div class="order-detail">
    <wrapper-with-loader :isLoading="isLoading">
      <breadcrumb-list :breadcrumbList="breadcrumbList" />
      <page-title :pageTitle="pageTitle" />
      <template v-if="order && orderDetail">
        <div class="flex flex-col">
          <template v-if="order.paymentType === 2">
            <order-detail-action :order="order" :fetchOrder="fetchOrder" />
          </template>
          <order-detail-summary :order="order" />
          <content-wrapper>
            <h2 class="text-lg font-medium mb-2">
              {{ $t('user.order.congressDetailInfo') }}
            </h2>
            <div v-if="downXl" class="w-full">
              <order-detail-table-list :orderDetail="orderDetail" />
            </div>
            <div v-else>
              <order-detail-accordion-list :orderDetail="orderDetail" />
            </div>
          </content-wrapper>
        </div>
      </template>
    </wrapper-with-loader>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useMeta } from 'vue-meta'
import { mapGetters } from 'vuex'
import { useResponseToast, useToast, useMediaQuery } from '@/common/hooks'
import {
  BreadcrumbList,
  PageTitle,
  WrapperWithLoader,
  ContentWrapper,
} from '@/components'
import OrderDetailAccordionList from './OrderDetailAccordionList.vue'
import OrderDetailSummary from './OrderDetailSummary.vue'
import OrderDetailTableList from './OrderDetailTableList.vue'
import OrderDetailAction from './OrderDetailAction.vue'
import { service } from '@/common/services'

export default {
  name: 'OrderDetailPage',
  components: {
    WrapperWithLoader,
    BreadcrumbList,
    ContentWrapper,
    PageTitle,
    OrderDetailAccordionList,
    OrderDetailSummary,
    OrderDetailTableList,
    OrderDetailAction,
  },
  data() {
    return {
      isLoading: true,
      breadcrumbList: [{ name: this.$t('sidebar.myOrder'), url: 'userOrder' }],
      pageTitle: this.$t('sidebar.orderDetail'),
      order: null,
      orderDetail: null,
      orderId: this.$route.params.id,
    }
  },
  setup() {
    const { downXl } = useMediaQuery()
    const toast = useToast()
    return { toast, downXl }
  },
  mounted() {
    this.fetchOrder()
    this.fetchOrderDetail()
  },
  created() {
    useMeta(
      computed(() => ({
        title: `${this.$t('metaTitle.orderDetail')} - ${this.getLang === 'TR'
            ? this.order?.congressName
            : this.order?.congressNameEn
          }`,
      })),
    )
  },
  watch: {
    order: function (data) {
      return data
    },
  },
  methods: {
    fetchOrder() {
      service
        .fetch(`order/${this.orderId}`)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.order = response
              this.breadcrumbList.push({ name: response.congressName })
            },
            errorCallback: () => { },
            isSaveMessage: false,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    fetchOrderDetail() {
      service
        .fetch(`order-detail/${this.orderId}`)
        .then((response) => {
          useResponseToast({
            response: response,
            successCallback: () => {
              this.orderDetail = response
            },
            errorCallback: () => { },
            isSaveMessage: false,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  computed: {
    ...mapGetters(['getLang']),
  },
}
</script>

<style scoped lang="scss">
.order-detail {
  @apply h-full;
}
</style>
