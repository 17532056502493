<template>
  <div class="basket">
    <wrapper-with-loader :isLoading="isLoading">
      <template v-if="getBasket && getBasket.items.length">
        <step-wizard :step="Number(1)" :stepList="stepList" />
        <div class="flex flex-col gap-5 xl:flex-row">
          <content-wrapper externalClass="w-full xl:w-2/3 md:mb-4">
            <div v-if="downXl">
              <basket-table-list :basket="getBasket" />
            </div>
            <div v-else>
              <basket-accordion-list :basket="getBasket" />
            </div>
          </content-wrapper>
          <basket-summary :basket="getBasket" />
        </div>
      </template>

      <template v-else>
        <no-data>
          <div class="text-2xl font-bold mb-2 text-gray-900">
            {{ $t('user.basket.emptyBasket') }}
          </div>
          <div class="text-sm font-normal text-gray-500">
            {{ $t('user.basket.emptyBasketDescription') }}
          </div>
        </no-data>
      </template>
    </wrapper-with-loader>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useMediaQuery, useToast, useMeta } from '@/common/hooks'
import {
  WrapperWithLoader,
  NoData,
  ContentWrapper,
  StepWizard,
} from '@/components'
import BasketTableList from './BasketTableList.vue'
import BasketAccordionList from './BasketAccordionList.vue'
import BasketSummary from './BasketSummary.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'BasketPage',
  components: {
    NoData,
    WrapperWithLoader,
    BasketTableList,
    BasketAccordionList,
    BasketSummary,
    ContentWrapper,
    StepWizard,
  },
  data() {
    return {
      isLoading: true,
      stepList: [
        {
          key: 1,
          value: this.$t('shared.basket'),
        },
        {
          key: 2,
          value: this.$t('shared.payment'),
        },
        {
          key: 3,
          value: this.$t('shared.finish'),
        },
      ],
    }
  },
  setup() {
    const { downXl } = useMediaQuery()
    const toast = useToast()
    return { toast, downXl }
  },
  created() {
    useMeta(
      computed(() => ({
        title: this.$t('metaTitle.basket'),
      })),
    )
  },
  mounted() {
    if (this.getBasket) {
      this.isLoading = false
    }
  },
  watch: {
    getBasket: function (data) {
      this.isLoading = false
      return data
    },
  },
  computed: {
    ...mapGetters(['getBasket']),
  },
}
</script>

<style scoped lang="scss">
.basket {
  @apply h-full;
}
</style>
